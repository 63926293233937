import {
  Box,
  Container,
  TextField,
  Typography,
  OutlinedInput,
  Button,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import ic_drawer from "../../assets/images/ic_drawer.png";
import Text from "components/Text/Typography";
import SwipeableViews from "react-swipeable-views";
import { PinDrop, Delete } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import apis from "services/apis";

import { SocketContext } from "services/SocketContext"; // Import đúng context

const ListChatPage = () => {
  const catelogy = ["All", "Unread", "Read", "Pinned"];
  const [selectedCategory, setSelectedCategory] = useState("All");
  const [data, setData] = useState([]);
  const [swipedIndex, setSwipedIndex] = useState(null);
  const navigate = useNavigate();
  const socket = useContext(SocketContext);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      const response = await apis.getListMessage();
      console.log("response==", response);
      if (response?.results) {
        setData(response?.results);
      }
    } catch (error) {
      console.log("ERROR", error);
    }
  };

  useEffect(() => {
    // Khi socket kết nối thành công
    socket.on("connect", () => {
      console.log("Kết nối socket thành công:", socket.id);
      onJoinRoom();
    });

    // Khi socket ngắt kết nối
    socket.on("disconnect", () => {
      console.log("Socket bị ngắt kết nối");
    });

    socket.on("connect_error", (error) => {
      console.log("Kết nối socket bị lỗi:", error);
    });
  }, []);

  const onJoinRoom = () => {
    const token = localStorage.getItem("token");
    const params = {
      token: token,
    };
    socket.emit("join-admin", params);

    socket.on("new-conversation", (event) => {
      console.log("event==", event);
      getData();
    });
    
  };

  const handleChatClick = async (chatId) => {
    navigate(`/main/chat/${chatId}`, { id: chatId });
  };

  const getRandomColor = () => {
    const letters = "0123456789ABCDEF";
    let color = "#";
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };

  const _renderChatList = () => {
    return (
      <Box sx={{ display: "flex", flexDirection: "column", marginTop: 1 }}>
        {data.map((item, index) => {
          return (
            <Box
              key={index}
              sx={{ display: "flex", flexDirection: "row", marginTop: 2.5 }}
              onClick={() => handleChatClick(item.userId)}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  width: 50,
                  height: 50,
                  borderRadius: 50,
                  backgroundColor: getRandomColor(),
                }}
              >
                <Text
                  style={{
                    color: "#FFFFFF",
                    fontSize: 16,
                    fontWeight: "bold",
                  }}
                  title={
                    item?.userInfo?.fullName ? item.userInfo.fullName[0] : "V"
                  }
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  marginLeft: 2.5,
                }}
              >
                <Text
                  style={{
                    color: "#000000",
                    fontSize: 16,
                    fontWeight: "bold",
                  }}
                  title={
                    item?.userInfo?.fullName
                      ? item.userInfo.fullName
                      : "Visitor"
                  }
                />
                <Text
                  style={{
                    color: item?.notSeen > 0 ? "#1E68D7" : "#000000",
                    fontSize: 14,
                  }}
                  title={
                    item.mimeType === "image"
                      ? (item.sender === item.userId ? "Khách" : "Bạn") +
                        " đã gửi 1 ảnh"
                      : item.sender !== item.userId
                      ? "Bạn đã gửi 1 tin nhắn"
                      : item.content
                  }
                />
                <Text
                  style={{
                    color: item?.notSeen > 0 ? "#1E68D7" : "#000000",
                    fontSize: 14,
                  }}
                  title={"Nguồn: " + item.app}
                />
              </Box>
              <Box
                sx={{
                  marginLeft: "auto",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Text
                  style={{ color: "#000000", fontSize: 14 }}
                  title={item.time}
                />
                {item?.notSeen > 0 && (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      backgroundColor: "#1E68D7",
                      borderRadius: 20,
                      width: 24,
                      height: 24,
                      marginTop: 1,
                    }}
                  >
                    <Text
                      style={{
                        color: "#FFFFFF",
                        fontSize: 14,
                        fontWeight: "bold",
                      }}
                      title={item.notSeen}
                    />
                  </Box>
                )}
              </Box>
            </Box>
          );
        })}
      </Box>
    );
  };
  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          marginTop: 2.5,
          alignItems: "center",
          position: "relative",
        }}
      >
        <img src={ic_drawer} style={{ height: 20, width: 22 }} />
        <Text
          style={{
            color: "#000",
            fontSize: 25,
            textAlign: "center",
            position: "absolute",
            left: "50%",
            transform: "translateX(-50%)",
          }}
          title={"Chats"}
        />
      </Box>
      {_renderChatList()}
    </Container>
  );
};

export default ListChatPage;
