const { default: network } = require("./network");

const register = (data) => {
  return new Promise((resolve, reject) => {
    network
      .unAuthorizedRequest("authentication/register", "POST", data)
      .then((response) => {
        if (response?.statusCode >= 400) {
          reject(response);
        }
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const login = (data) => {
  return new Promise((resolve, reject) => {
    network
      .unAuthorizedRequest("authentication/login-admin", "POST", data)
      .then((response) => {
        if (response?.statusCode >= 400) {
          reject(response);
        }
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const getProfile = () => {
  return new Promise((resolve, reject) => {
    network
      .authorizedRequest("user/profile", "GET")
      .then((response) => {
        if (response?.statusCode >= 400) {
          reject(response);
        }
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const getListMessage = () => {
  return new Promise((resolve, reject) => {
    network
      .authorizedRequest("admin/message/dashboard", "GET")
      .then((response) => {
        if (response?.statusCode >= 400) {
          reject(response);
        }
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const loginUser = (data) => {
  return new Promise((resolve, reject) => {
    network
      .unAuthorizedRequest("authentication/login", "POST", data)
      .then((response) => {
        if (response?.statusCode >= 400) {
          reject(response);
        }
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const getDetailChat = (id) => {
  return new Promise((resolve, reject) => {
    network
      .authorizedRequest(`admin/message/history/${id}?limit=9999`, "GET")
      .then((response) => {
        if (response?.statusCode >= 400) {
          reject(response);
        }
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const markRead = (id) => {
  return new Promise((resolve, reject) => {
    network
      .authorizedRequest(`admin/message/mark-seen/${id}`, "PUT")
      .then((response) => {
        if (response?.statusCode >= 400) {
          reject(response);
        }
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const uploadImage = (file) => {
  return new Promise((resolve, reject) => {
    const formData = new FormData();
    formData.append("file", file);
    network
      .authorizedRequest("public/upload", "POST", formData, {
        "Content-Type": "multipart/form-data",
      })
      .then((response) => {
        if (response?.statusCode >= 400) {
          reject(response);
        }
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export default {
  register,
  login,
  getProfile,
  getListMessage,
  loginUser,
  getDetailChat,
  markRead,
  uploadImage,
};
